<template>
<div class="hello">
  <Transition>
    <AreasDePractica v-if="area==''" :lang="lang" />
    <AreaDePractica v-else :area="area" :lang="lang" />
  </Transition>
</div>
</template>

<script>
import AreasDePractica from './AreasDePractica'
import AreaDePractica from './AreaDePractica'
export default {
  name: 'AreasHandler',
  components: {
    AreasDePractica,
    AreaDePractica
  },
  props: {
    lang: {
      type: String,
      default: 'es'
    },
    area: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.helloimg {
  display: block;
  padding: 20px;
  max-width: 30vw;
}

.area {
  background-color: white;
  min-height: 100vh;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
