<template>
  <section
    class="
      areasdepractica
      text-center
      align-items-center
      justify-contents-center
    "
    id="about"
  >
    <div class="row areasdescripcion min-vh-100">
      <div class="ug col-xs-12 col-md-6 px-5 texto text-justify">
        <div v-if="lang == 'es'">
          <p>
            Bufete de la Garza S.C. fue fundado en 1985 con el objetivo de
            brindar servicios legales de la más alta calidad y de forma integral
            a clientes mexicanos y extranjeros.
          </p>
          <p>
            Nuestra filosofía es prestar servicios profesionales que estructuren
            propuestas de solución efectiva a las necesidades legales, todo ello
            apegado a las normas de la ética profesional.
          </p>
          <p>
            Actualmente la firma está compuesta por abogados egresados de las
            mejores universidades de México, quienes en su mayoría han
            completado su formación con estudios de posgrado y son especialistas
            en diferentes áreas del derecho. Nuestros colaboradores están
            capacitados para ofrecer un trabaio de alta calidad apegado a los
            valores que unen al bufete.
          </p>
          <p>
            El bufete presta sus servicios profesionales en las áreas del
            derecho que se mencionan a continuación:
          </p>
        </div>
        <div v-else>
          <p>
            Bufete de la Garza S.C. was estabilished in 1985 looking to offer
            quality superior, integral legal services to mexican and foreign
            clients.
          </p>
          <p>
            Our philosophy consists on rendering professional law services that
            can build effective solutions to your legal needs, while geting
            aligned to professional ethics norms.
          </p>
          <p>
            Currently the Firm have lawyers coming from the best Mexico
            colleges, most of them finished their education being postgraduates,
            thus having different law specialties. Our collaborators are
            qualified to offer high standards work, while being advocate to the
            Firm values.
          </p>
          <p>
            The Firm renders professional services in the following practice
            areas:
          </p>
        </div>
      </div>
      <div class="ug col-xs-12 col-md-6">
        <h2 class="vertical titulo text-bdlg w-100 text-sm">
          {{ titulo[lang] }}
        </h2>
        <ul class="listaDerechos uppercase mmx-5">
          <li v-for="(d, indice) in derechos" :key="indice">
            <a :href="'#/areas/' + d._id">{{ d.titulo[lang] }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<style scoped>
.areasdepractica {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  background-image: url(../../public/g858.png);
}

.numAreasDePractica {
  font-size: 4em;
}

.botonVerMas {
  font-size: 0.8em;
  text-transform: none;
}

.listaDerechos {
  text-align: left;
  list-style: none;
  margin-left: 0.5em;
}

@media (min-width: 768px) {
  .listaDerechos {
    padding-bottom: 3em;
    margin-top: -1em;
    //text-align: center;
    margin-left: 3em;
  }

  .vertical {
    text-align: right;
    text-transform: uppercase;
    transform: rotate(-90deg);
    position: absolute;
    top: 235px;
    left: -125px;
    bborder: 2px solid #cccccc;
    width: auto !important;
  }

  .listaDerechos li {
    padding-top: 0.6em;
  }
}

.areasdepractica a {
  text-decoration: none;
}

.areasdescripcion {
  font-size: 1em;
}

.areasdescripcion .texto {
  background-color: #dadada;
  padding-bottom: 2em;
}

@media (max-width: 767px) {
  .areasdescripcion .texto {
    display: none;
  }

  .vertical {
    text-transform: uppercase;
    padding-bottom: 1em;
    text-align: left;
    max-width: 80%;
    margin-left: 1.5em;
  }
}

.areasdescripcion .ug {
  padding-top: 5em;
}
</style>
<script>
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "AreasDePractica",
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: "https://app.haakon.cc:8083/rvrs",
      derechos: [],
      titulo: {
        es: "Áreas de práctica",
        en: "Practice areas",
      },
      botonTitulo: {
        es: "Ver más",
        en: "Show more",
      },
    };
  },
  async mounted() {
    try {
      this.derechos = (
        await axios.post(
          this.url + "/readbypass/areaweb",
          {},
          { headers: { "wst-appkey": "bdlg.mx" } }
        )
      ).data
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
        .concat(this.derechos);
    } catch (e) {
      console.error(e);
    }
    //.map(a => a.titulo[this.lang])
    console.log("derechos?", this.derechos);
  },
};
</script>
