<template>
  <div class="area">
    <div class="row">
      <div class="ug col-xs-12 col-md-6 h-min-full">
        <div class="container px-5">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h2 class="text-uppercase text-bdlg">
            {{ areaDescargada.titulo[lang] }}
          </h2>
          <p>&nbsp;</p>
          <p class="text-justify text-large">
            {{ areaDescargada.descripcion[lang] }}
          </p>
        </div>
        <!--pre v-if="!modoProduccion">{{areaDescargada}}</pre-->
      </div>
      <div class="ug col-xs-12 col-md-6 h-min-full bg-gris">
        <div class="container px-5">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h3 class="text-bdlgg">
            {{ tituloServicios[lang] }}
            <a class="float-right botonAtras" :href="'#/areas'">
              <p>{{ botonAtras[lang] }}</p>
            </a>
          </h3>
          <p>&nbsp;</p>
          <p
            class="text-justify"
            v-if="typeof areaDescargada.servicios[lang] == 'string'"
            v-html="areaDescargada.servicios[lang]"
          ></p>
          <ul v-else>
            <li
              class="text-justify"
              v-for="u in areaDescargada.servicios[lang]"
              :key="u"
            >
              {{ u }}
            </li>
          </ul>
          <div v-if="abogados.length > 0">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h3 class="text-bdlg" v-if="lang == 'es'">Abogados relacionados</h3>
            <h3 class="text-bdlg" v-else>Related lawyers</h3>
            <ul class="listaAbogados py-2">
              <li v-for="a in abogados" :key="a._id">
                <a :href="'#/abogados/' + a._id">{{ a.nombre }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.listaAbogados {
  list-style: none;
}

.listaAbogados li {
  margin-bottom: 0.5em;
  margin-left: 0;
  font-size: 1.2em;
}

.listaAbogados a {
  text-decoration: none;
  color: black;
}

.botonAtras {
  font-size: 0.5em;
  padding-top: 0.5em;
}
</style>
<script>
import Api from "@/components/Api";
import axios from "axios";
export default {
  name: "AreaDePractica",
  props: {
    area: {
      type: String,
      default: "area",
    },
  },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: "https://app.haakon.cc:8083/rvrs",
      modoProduccion: Api.modoProduccion,
      areaDescargada: {
        titulo: {
          es: "Área de práctica",
          en: "Practice area",
        },
        descripcion: {
          es: "",
          en: "",
        },
        servicios: {
          es: "",
          en: "",
        },
      },
      abogados: [
        {
          nombre: "Juan Perez",
          _id: "0",
        },
        {
          nombre: "Pedro Perez",
          _id: "1",
        },
      ],
      tituloServicios: {
        es: "Nos especializamos en",
        en: "We specialize in",
      },
      botonAtras: {
        es: "Atrás",
        en: "Back",
      },
    };
  },
  async mounted() {
    try {
      this.areaDescargada = (
        await axios.post(
          this.url + "/readbypass/areaweb",
          {},
          { headers: { "wst-appkey": "hola" } }
        )
      ).data.filter((a) => a._id == this.area)[0];
      this.areaDescargada.servicios.es = this.areaDescargada.servicios.es
        .replace(/●/g, "<br />")
        .replace(/,/g, "<br />")
        .split("<br />");
      this.areaDescargada.servicios.en = this.areaDescargada.servicios.en
        .replace(/●/g, "<br />")
        .replace(/,/g, "<br />")
        .split("<br />");
      this.abogados = (
        await axios.post(
          this.url + "/readbypass/usuarioweb",
          {},
          { headers: { "wst-appkey": "bdlg.mx" } }
        )
      ).data.filter((a) => this.areaDescargada.abogados.indexOf(a._id) >= 0);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
